'use client';

import {useEffect} from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import {ILocation} from '@/models/location';

import {setLocalization} from '@/actions/localization';
import {useLocalization} from '@/hooks/useLocalization';
import useCartStore from '@/stores/cart/store';
import ngeohash from 'ngeohash';

function useAddressSearch(q: string) {
  const cart = useCartStore((state) => state);
  const localization = useLocalization();
  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: process.env.NEXT_PUBLIC_KEY_API_PLACES,
    debounce: 300,
    language: 'it',
    options: {
      input: q,
      componentRestrictions: {country: 'it'},
      types: ['address'],
    },
  });

  useEffect(() => {
    if (isPlacePredictionsLoading) {
      return;
    }
    getPlacePredictions({input: q});
  }, [q]);

  function placeToLocation(place: google.maps.places.PlaceResult) {
    return {
      address: place.address_components!.find((a) => a.types.includes('route'))
        ?.long_name,
      streetNumber: place.address_components!.find((a) =>
        a.types.includes('street_number'),
      )?.long_name,
      city:
        place.address_components!.find((a) => a.types.includes('locality'))
          ?.long_name ||
        place.address_components!.find((a) =>
          a.types.includes('administrative_area_level_3'),
        )?.long_name,
      postalCode: place.address_components!.find((a) =>
        a.types.includes('postal_code'),
      )?.long_name,
      formattedAddress: place.formatted_address,
      coordinates: {
        lat: place.geometry!.location!.lat(),
        lng: place.geometry!.location!.lng(),
      },
    };
  }

  function findLocationFromPrediction(
    prediction: google.maps.places.AutocompletePrediction,
  ) {
    return new Promise<ILocation>((resolve, reject) => {
      placesService!.getDetails(
        {
          placeId: prediction.place_id,
          fields: [
            'address_component',
            'formatted_address',
            'geometry.location' /*, 'name'*/,
          ],
        },
        (result) => {
          if (!result) {
            return reject(new Error('no prediction found'));
          }
          const location = placeToLocation(result);
          // if (!location.streetNumber) {
          //   return reject(new Error('no street number'));
          // }
          // @ts-ignore
          resolve(location);
        },
      );
    });
  }

  async function updateGlobalLocation(location: ILocation) {
    const {lat, lng} = location.coordinates;
    const geoHash = ngeohash.encode(lat!, lng!);
    cart.emptyCart();
    const result = await setLocalization(location, geoHash);
    localization.setState(result);
  }

  return {
    isLoading: isPlacePredictionsLoading,
    predictions: placePredictions,
    findLocationFromPrediction,
    updateGlobalLocation,
  };
}

export default useAddressSearch;
